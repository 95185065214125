(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/group-header/assets/javascripts/group-header.js') >= 0) return;  svs.modules.push('/components/marketplace/group-header/assets/javascripts/group-header.js');

'use strict';

const {
  ReactIcon
} = svs.ui;
const {
  roles
} = svs.components.marketplaceData.auth;
const GroupHeader = _ref => {
  let {
    className,
    color,
    displayMenu,
    isCaptain,
    isInverted,
    isUnreadCountVisible,
    myRole,
    onClickEndTeam,
    onClickLeaveTeam,
    onGoBack,
    teamName,
    unreadMessages
  } = _ref;
  const buttonPermissions = {
    canEndTeam: displayMenu && myRole && roles.hasPermission(myRole, roles.PERMISSIONS.END_TEAM),
    canLeave: displayMenu && myRole && roles.hasPermission(myRole, roles.PERMISSIONS.LEAVE_TEAM)
  };
  const {
    canEndTeam,
    canLeave
  } = buttonPermissions;
  const RightButton = () => React.createElement("div", {
    className: "btn-group group-header-right-buttons"
  }, isCaptain && React.createElement("div", null, React.createElement(ReactIcon, {
    className: "margin-right margin-left",
    color: "fc-black",
    icon: "team-captain",
    size: "300"
  })), canLeave && React.createElement("div", null, React.createElement("button", {
    className: "btn btn-100 btn-transparent-default no-wrap margin-0 popover-action-btn qa-action-btn f-left",
    onClick: onClickLeaveTeam,
    type: "button"
  }, "L\xE4mna lag")), canEndTeam && React.createElement("div", null, React.createElement("button", {
    className: "btn btn-100 btn-transparent-default no-wrap margin-0 popover-action-btn qa-action-btn f-left",
    onClick: onClickEndTeam,
    type: "button"
  }, "Avsluta lag")));
  const renderUnreadMessagesIcon = () => React.createElement("div", {
    className: "team-unread-messages-container"
  }, React.createElement(ReactIcon, {
    color: "fc-grey-400",
    icon: "chat-filled team-unread-messages-icon",
    size: "300"
  }), React.createElement("span", {
    className: "team-unread-messages-text f-medium"
  }, unreadMessages > 99 ? '99+' : unreadMessages));
  const rootClassNames = ['team-header'];
  if (isInverted) {
    rootClassNames.push('inverted');
  }
  if (className) {
    rootClassNames.push(className);
  }
  const style = {};
  if (color) {
    style['--color'] = color;
  }
  return React.createElement("div", {
    className: "team-header-holder"
  }, React.createElement("div", {
    className: rootClassNames.join(' '),
    style: style
  }, React.createElement("div", {
    className: "team-header-first-row"
  }, React.createElement("div", {
    className: "back-container"
  }, onGoBack ? React.createElement("button", {
    className: "qa-back-button icon-button",
    onClick: onGoBack,
    type: "button"
  }, React.createElement(ReactIcon, {
    icon: "arrow-left",
    size: "300"
  })) : React.createElement("div", null)), isUnreadCountVisible && unreadMessages > 0 && React.createElement("div", {
    className: "team-unread-messages"
  }, renderUnreadMessagesIcon())), React.createElement("div", {
    className: "team-title f-bold f-700"
  }, teamName), React.createElement(RightButton, null)));
};
GroupHeader.defaultProps = {
  className: '',
  displayMenu: false,
  history: {
    push: () => null
  },
  isInverted: false,
  isUnreadCountVisible: true
};
setGlobal('svs.components.marketplace.groupHeader.GroupHeader', GroupHeader);

 })(window);